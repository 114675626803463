
// -----------------------------------------------------------------------------
// This file contains styles that are specific to the jobs page.
// -----------------------------------------------------------------------------

.jobs {
background: linear-gradient(to right, #8860d0 30%, #1a54e5 100%);
padding: 140px 0;
}

.hero-clip {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 79%, 0 100%);
clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 79%, 0 100%);
}

.mid {
      clip-path: polygon(
    0 0, /* left top */
    100% 10%, /* right top */ 
    100% 100%, /* right bottom */
    0 90% /* left bottom */
    );
}


.mid-2 {
-webkit-clip-path: polygon(0 8%, 100% 0, 100% 92%, 0% 100%);
clip-path: polygon(0 8%, 100% 0, 100% 92%, 0% 100%);
}

.mid-3 {
background-image: linear-gradient(20deg, rgba(136,96,208,0.78), rgba(43,134,253,0.88)), url(/assets/img/recruiters.jpg);
     clip-path: polygon(
    0 0, /* left top */
    100% 10%, /* right top */ 
    100% 100%, /* right bottom */
    0 90% /* left bottom */
    );
}

.recent_jobs {
  background-image: linear-gradient(20deg, rgba(136,96,208,0.78), rgba(43,134,253,0.88)), url(/assets/img/scrum.jpg);
}