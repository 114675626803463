/**
 * Basic typography style for copy text
 */
body {
  color:#23344b;
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6, .h1, .h2 {
  text-rendering: optimizeLegibility;
}

.handwriting {
  font-family: 'Permanent Marker', cursive;
}