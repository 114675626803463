// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

body {
  font-size: 16px;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *::before, *::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $brand-color;
  text-decoration: none;

  @include on-event {
    color: $text-color;
    text-decoration: underline;
  }
}

.grid2x2 {
  .item {
    p {
      padding-left: 50px;
    }
  }
}

.navbar {
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  -ms-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.bg-nav {
  background: #ffffff63;    
 
   @include media-breakpoint-up(md) { 
       background:none;
   }
   @include media-breakpoint-up(lg) { 
       background: none;
   }
}



.navbar.scrolled {
   background: white;
   border-bottom: 1px solid #f5f5f5;
   box-shadow: 0 2px 10px rgba(0,0,0,.25);
   .nav-item {
      a {
          border: 2px solid $purple;
          color: $purple;
            &:hover {
              color: white;
            }
        }
          
      }
 }

 .nav-item, .type {
      a {
        border: 2px solid #ffffff;
        border-radius: 2rem;
        padding: 8px 23px;
        color: white;
        &:hover {
          background-color: $purple;
          text-decoration: none;
        }
      }


 }

 .my-10 {
   margin: 6rem 0;
 }

.label {
    font-weight: bold;
    font-size: 12px;
    padding: 0 0 6px;
    }

    .header {
  text-shadow: 1px 1px #9673d5;
  font-weight: 700;
    }

    p.lead {
      font-weight: 500;
      font-size: 1.75rem;
    }

.form-control {
  height: 55px;
  border-radius: 12rem;
  padding: .375rem 1.25rem;
  border: 2px solid $secondary;
}

form {
  border-radius: 7px;
  box-shadow: 0 7px 10px rgba(0,0,0,0.25);
}

form.modal-form {
  box-shadow: none;
}

.btn-round {
  border: 2px solid #ffffff;
  border-radius: 2rem;
  padding: 8px 23px;
  color: white;
    &:hover {
      text-decoration: none;
    }
}

.offset-0 {
  margin-right: auto;
  margin-left: auto;
}

.circle-border {
 border: 15px solid $primary;
}
