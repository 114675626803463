// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

.icon-bar {
  width: 100%; /* Full-width */
  background-color: rgb(243, 243, 243); /* Dark-grey background */
  overflow: auto; /* Overflow due to float */
  border-style: solid;
  border-color: $primary;
  border-width: 1px 0;
  span {
    float: left; /* Float links side by side */
    text-align: center; /* Center-align text */
    width: 20%; /* Equal width (5 icons with 20% width each = 100%) */
    padding: 12px 0; /* Some top and bottom padding */
    transition: all 0.3s ease; /* Add transition for hover effects */
    color: white; /* White text color */
    font-size: 36px; /* Increased font size */
    img {
      max-height:18px;
    }
  }
}

.index {
  font-weight: 500;
  color: white;
  font-size: 1.75rem;
  background-image: linear-gradient(20deg, rgba(136, 96, 208, 0.78), rgba(43, 134, 253, 0.88)), url(/assets/img/hero_bg.jpg);
  background-size: cover;
  background-position: top center;
}


.hero {
  background-size: cover;
  background-position:center center;
}

.banner {
  background-image: linear-gradient(20deg, rgba(136, 96, 208, 0.78), rgba(43, 134, 253, 0.88)), url(/assets/img/blue-people.jpg);
}

