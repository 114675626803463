// -----------------------------------------------------------------------------
// This file contains styles that are specific to the employer page.
// -----------------------------------------------------------------------------

.employers {
background: linear-gradient(to right, #8860d0 30%, #1a54e5 100%);
padding: 140px 0;
-webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 91%);
clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 91%);
}

.connect {
  margin-top: -73px;
}